
export default {
  props: ['items'],
  name: 'Footer',
  data() {
    return {
      footer: [
        { title: 'home', route: '/' },
        { title: 'agri-business', route: '/agri-business' },
        { title: 'about', route: '/about' },
        { title: 'agri-finance', route: '/agri-finance' },
        { title: 'e-learning', route: '/e-learning' },
        { title: 'agri-market', route: '/agri-market' },
        { title: 'advisory', route: '/advisory' },
      ],
      services: [
        { title: 'consultation', route: '/' },
        { title: 'market-price', route: '/' },
        { title: 'how-to-open-business', route: '/' },
      ],
    }
  },
}
